@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');


.font_heading{
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    font-size: 23px !important;
}

.font_sub_heading{
    font-family: 'Lato', sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.font_basic{
    font-family: 'Lato', sans-serif !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

.font_family_common{
    font-family: 'Lato', sans-serif !important;
}

.font_paragraph{
    font-family: 'Lato', sans-serif;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.cursor-pointer{
    cursor: pointer !important;
}

.lh-sm{
    line-height: 19px;
}

.font_500{
    font-weight: 500 !important;
}

.font_16{
    font-size: 16px !important;
}

.font_10{
    font-size: 10px !important;
}

.bg_green{
    background-color: #0C831F !important;
}

.bg_disable{
    background-color: rgba(157, 157, 158, 0.904);
}

.text_green{
    color:#0C831F ;
}

input[type=file]::file-selector-button {
    visibility: hidden;
}

.required:after {
    content:" *";
    color: red;
}


@media screen and (max-width: 600px){
    .font_heading{
        font-size: 18px !important;
        line-height: 1.5 !important;
    }
    .title.font_heading{
        padding-top:1rem !important ;        
        padding-bottom:1rem !important ;        
    }

    .title span
    {
        font-size: 12px !important;
    }

    .font_basic.flTNGy
    {
        font-size: 12px !important;
    }
}