.footer_bottom_content_alignment_width{
    width:98px !important;
}

.footer_bottom_parent{
    background-color: #FCFCFC;
}

.footer_bottom_parent .bottom_center_div{
    width: 88% !important;
}

.category_list_container{
    width:  450px ;
}

@media screen and (max-width:900px){
    .footer_bottom_parent .bottom_center_div{
        width: 100% !important;
    }
}

@media screen and (max-width: 600px){
    .category_list_container{
        width:  100% !important;
    }
}