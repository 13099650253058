
.footer_category_details_page{
    margin-top: 130px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.social_links{
    float: left;
}
.social_links li{
    margin-left: 20px;
    margin-top: 10px;    
}

.social-icon{
    height: 40px;
    width: 40px;
}

@media screen and (max-width: 900px){
    .footer_category_details_page{
        margin-top: 175px !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
}