.selected_brand_page{
    margin-top: 120px !important;
}

.selected_brand_page .col-8{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.selected_brand_page .col-lg-8{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.empty_data_row{
    height: 200px;
}

@media screen and (max-width:900px){
    .selected_brand_page{
        margin-top: 180px !important;
    }
}