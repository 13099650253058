.order_card{
    border: 0.2px solid lightgray;
}

.order_card .row .col-2 .order_product_image{
    height: 80px;
    width: 80px;
    border-radius: 8px;
    padding: 5px;
    border: 0.2px solid lightgray;
}
.order_card .row .col-2 .order_product_image img{
    height: 100%;
}

.order_new_tag{
    height: 30px;
    background-color: rgb(154, 153, 153);
    width: auto;
}

.bg_greyshade{
    background-color: rgba(154, 153, 153, 0.125);
}

.order_card_bottom .col-4 span{
    font-size: 13px;
    font-weight: 500;
}