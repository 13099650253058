  .overlay{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.6);
    position: absolute;
    top: 0;
    z-index: 10000;
    left: 0;
  }

  .delete_item_btn img{
    height:20px;
    width:20px;
  }

  .cart_container{
    height: calc(100vh - 251px) !important;
    overflow: auto;
  }

  .cart_container::-webkit-scrollbar{
    width:8px;
    color:gray;
  }

  .cart_container::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  .cart_container::-webkit-scrollbar-thumb {
      background: gray;
      border-radius:10px;
  }

  .right_cart_list{
    z-index: 10000 ;
  }

    .right_cart_list .offcanvas-header{
      border-bottom: 11px solid #EEEEEE !important;
    }

    .offcanvas-body .total_amount{
      right: 0px  !important;
      bottom: 20px ;
      width: 100% !important;
    }

    .cart_order_details{
      border-top: 11px solid #EEEEEE;
      border-bottom: 11px solid #EEEEEE;
    }

    .total_amount p .proceed_btn_arrow{
      height:14px ; 
      width:14px;
      margin:2px 0 0 5px;
    }

    .empty_cart_view{
      height: 72vh;
    }

    
    @media screen and (max-width:900px){
      .right_cart_list{
        width: 60% !important;
      }
    }
    
    @media screen and (max-width:600px){
      .right_cart_list{
        width: 100% !important;
      }
    }


    .coupon_applied_box{
      background: #fafafa;
      padding:20px 6px 20px 15px ;
      border-radius: 4px;    
      font-size: 16px;
      font-weight: 700;;
    }
    .coupon_applied_box .fa{
      color: #0C831F !important;        
    }
    
    .couponCode{
      color: #000 !important;  
    }
    .remove_coupon_btn{
      text-align: right;
      color:red !important;
      font-weight: 700;
      cursor: pointer;
    }
    .text-red{
      color:red !important;
      font-weight: 500;
    }
    .text-green{
      color: #0C831F !important;
      font-weight: 500;
    }