.common_side_bar .userprofile_image{
    height: 111px;
    width: 210px !important;
    margin: 0 auto;
}


.common_side_bar .edit_icon{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 1px;
    position: absolute;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 80px;
    left: 150px;
    cursor: pointer;
}

@media screen and (max-width:900px){
    .common_side_bar .edit_icon{
        width: 28px;
        height: 28px;
        border-radius: 50%;
        padding: 1px;
        position: absolute;
        background-color: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        top: 80px;
        left: 118px;
        cursor: pointer;
    }
}