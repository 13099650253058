.modal_input_field_top{
    border: 1px solid lightgray;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.modal_custom_width{
    padding: 0 120px;
}

.modal_custom_width .modal-content .modal-body .address_other_details{
    background-color: #FAFAFA;
}

.name_input_group .form-select{
    width: 20%;
    border-radius: 8px 0 0 8px;
}

.name_input_group input{
    border-radius: 0 8px 8px 0;
}

.name_input_group .form-select:focus{
    box-shadow: none !important;
}

.modal_custom_width .modal-content .continue_btn{
    background-color: #0C831F;
}

.modal_custom_width .modal-content .continue_btn:hover{
    background-color: rgba(6, 109, 6, 0.83) !important;
}

.address_modal::-webkit-scrollbar {
    display: none;
}

.default_checkbox_title{
    margin-top: 3px;
}

.modal .header_address_alignment{
    position: absolute;
    top: 58px;
}

.modal .header_pincode_alignment{
    position: absolute;
    top: 58px;
    left: 50px;
}

.modal .header_pincode_alignment .modal-content{
    width: 350px;
}

.address_tabs p {
    min-height: 58px;
}

.address_tabs p:hover{
    background-color: #FAFAFA;
    cursor: pointer;
}

.header_address_alignment .modal-content .modal-body{
    height: 400px;
    overflow: auto;
}

.header_address_alignment .modal-content .modal-body::-webkit-scrollbar{
    width: 10px;
}
.header_address_alignment .modal-content .modal-body::-webkit-scrollbar-tract{
    background-color: aliceblue;
}
.header_address_alignment .modal-content .modal-body::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 10px;
}

.disabled{
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
}

/* mobile------------- */

@media screen and (max-width:600px){
    .modal_custom_width{
        padding: 0 0px;
    }
    .address_tabs p {
        height: 80px;
    }
}

/* tab-------- */

@media screen and (max-width:900px){
    .modal_custom_width{
        padding: 0 0 0 150px;
    }

    .header_address_alignment .modal-content{
        width:147% !important;
    }
    
}

/* mobile --------*/
@media screen and (max-width:600px){
    .modal_custom_width{
        padding:0px;
    }

    .header_address_alignment .modal-content{
        width:99% !important;
    }
    
    .modal .header_pincode_alignment .modal-content {
        width: 256px;
    }
    .name_input_group .form-select{
        width: 33%;
        border-radius: 8px 0 0 8px;
    }
}


