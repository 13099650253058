.sideBar{
    height: 712px;
}

.sideBar .sub_bars.active_sideBar{
    border-left: 4px solid #0984e3;
}

.sideBar .sub_bars{
    padding: 12px 0;
    border: 1px solid lightgray;
    border-bottom: 0px;
}

.sideBar .sub_bars:hover{
    background-color: #74baff47;
}

.sideBar .sidebar_image{
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.font_13{
    font-size: 13px;
}

.cat_name{
    width: 200px;
}
@media screen and (max-width:900px){
    .sideBar .sub_bars.active_sideBar{
        border-left: 1px solid lightgray;
        border-top: 4px solid #0984e3;
    }
    .sideBar .sub_bars{
        padding: 4px;
        width: 80px;
        height: 80px;
        margin:0 3px 2px 0;
        float: left;        
        border: 1px solid lightgray;
        border-bottom: 0px;
    }
    .sideBar .sub_bars p{
        font-size: 9px !important;
    }
    .sideBar .sidebar_image{
        height: 40px;
        width: 40px;
    }
    .cat_name{
        width: 100%;
        text-align: center;
        /* word-break: break-all; */
        justify-content: center;
    }
}

@media screen and (max-width:600px){
    
    


}