.order_switchable_container{
    height: calc(100vh - 250px);
    overflow: auto;
}

.order_switchable_container::-webkit-scrollbar{
    width: 6px;
}

.order_switchable_container::-webkit-scrollbar-track{
    background-color:  lightgray;
}

.order_switchable_container::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 10px;
}

@media screen and (max-width:900px){
    .profile_page_inner{
        margin-top: 220px !important;
    }
}

