.more_dropdown ul .list-item .header_item_link:hover{
    background: rgba(235, 233, 233, 0.959);
}

.category_header{
    position: fixed;
    /* top: 85px; */
    width: 100%;
    z-index: 900;
}

.category_header div ul{
    padding-bottom: 5px !important;
}

.active_div{
    border-bottom: 3.3px solid green;
    background-color: transparent !important;
}

.dropdown_custom_menu{
    height: 220px;
    overflow: scroll;
}

.product_cat_wise_main{
    margin-top: 155px;
}

/* tab--- */
@media screen and (max-width:900px){
    .category_header{
        position: fixed;
        top: 143px !important;
        width: 100%;
        z-index: 900;
    }
}
