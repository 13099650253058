
.productSlider .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right:0 !important;
    background:white !important;
    color: black !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.productSlider .react-multi-carousel-list{
    padding: 5px 5px;
}

.productSlider .react-multi-carousel-list .react-multiple-carousel__arrow--left{
    background:white !important;
    left:0 !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: black !important;
}

.productSlider .react-multi-carousel-list .react-multiple-carousel__arrow::before{
    color: black !important;
}

.productCard:hover{
    box-shadow: #9AC940 0px 0.2px 1px 0px, #9AC940 0px 0px 1px 0.2px;
}

.popup_quantity_input{
    width: 170px !important;
}

.out_stock_text{
    color: rgb(209, 45, 45);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px;
  }

.banner_container{
    /* height: 500px !important; */
    width: 100%;
    border-radius: 22px !important;
    text-align: center;
}


.banner_container.cursor{
 cursor: pointer;
}

.banner_container img{
    background-origin: padding-box;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: inline-block;
    background-size: cover !important;
}

.banner_container img{
    border-radius: 22px !important;
}

.card_bottom .card_add_btn{
    width: 100px;
}

.small-text{
    font-size: 10px;;
}
.carousel_heading{
    color: #ffffff !important;
    background: #cf2c54;
    border-radius: 50px;
    padding: 15px 20px;
    text-transform: uppercase;    
}

@media screen and (min-width: 600px) and  (max-width: 900px){
    .banner_container{
        height: 318px !important;
    }
}
@media screen and (max-width: 900px){
    .productSlider .react-multi-carousel-list{
        padding: 0 0 0 31px;
    }
}

@media screen and (max-width: 600px){
    .banner_container{
        /* height: 175px !important; */
        height: auto;
        margin: 5px auto !important;
    }
    .productSlider .react-multi-carousel-list{
        padding-left: 0 !important;
    }
    .card_bottom .card_add_btn {
        width: 65px !important;
    }
    .singleSlider
    {
        margin: 20px auto 5px !important;
    }
}
