.order_summary .row .cancel_order_text {
    color: rgba(255, 85, 0, 0.992);
}

.order_item_image{
    height: 80px;
    width: 80px;
    border-radius: 8px;
    padding: 5px;
    border: 0.2px solid lightgray;
}

.order_item_image img{
    height: 100%;
}

.border_bottom_large{
    border-bottom: 10px solid rgba(222, 220, 220, 0.549);
}

.reason_to_return_modal_textarea:focus{
    outline: none;
}

.reason_to_return_modal_textarea{
    resize: none;
    font-size: 18px !important;
    font-weight: 450;
}

.disable_btn{
    background-color: rgb(190, 189, 189);
}

@media screen and (max-width: 900px){
    .modal_custom_width {
        padding: 0px;
    }
}