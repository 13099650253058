.profile_page_inner{
    margin-top: 175px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0 22px;
}

.profile_image_main{
    /* height: 240px; */
    width: 240px;
}

.profile_image_main .userprofile_image{
    height: 140px;
}

.profile_image_main .edit_icon{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 2px;
    position: absolute;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 105px;
    left: 157px;
    cursor: pointer;
}

.profile_image_main .edit_icon label {
    position: absolute;
    top: 1px;
    left: 1px;
}

.profile_image_main .edit_icon input{
    z-index: 100;
    /* visibility: hidden; */
}

.upload-btn{
    width: 30px;
    height: 30px;
}

.upload-btn-edit-icon{
    position: absolute;
    top: 0;
}


@media screen and (min-width:600px) and  (max-width:900px){
    .profile_page_inner{
        margin-top: 220px !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 0 22px;
    }
}

@media screen and  (max-width:600px){
    .profile_page_mobile{
        margin-top: 180px !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        width: calc(100% - 33px) !important;
    }
   
}