.common_modal_title{
    font-size: 23px !important;
    font-weight: 200 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83%;
}

.common_modal_body{
    background-color: #F8F8F8;
    border-radius: 6px;
}

.common_modal_body div p {
    font-size: 18px;
    text-align: center;
}

.common_modal_input{
    width: 60%;
}

.common_modal_input input:focus{
    box-shadow: none;
    border: 1px solid lightgray !important;
    border-left: none !important;
}

.common_modal_input input{
    border-left: 0px;
    padding-left: 0;
    font-weight: 400;
    color: gray !important;
}

.common_modal_input .input-group-text {
    background-color: white;
    border-radius: 7px 0 0 7px;
    padding-right: 6px;
    color: gray;
}

.common_modal_input .input-group-text .input_phone_icon{
    height: 19px;
    width: 20px;
}

.common_modal_body .next_btn{
    padding: 7px 116px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
}

.next_btn_color{
    background-color: #0C831F;
}

.bg_disable{
    background-color: #4c4c4c63;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.next_btn_hover_effect:hover{
    background-color: rgba(6, 109, 6, 0.83) !important;
}

.otp_form .otp_input{
    width: 60px;
    height: 45px;
    font-weight: 400;
    color: gray !important;
}

input:focus{
    box-shadow: none !important;
}

.register_modal_body{
    width: 95%;
}

.login_register_modal::-webkit-scrollbar{
    display: none;
}
.link{
    color: rgb(13, 110, 253) !important;
    text-decoration: underline  !important;
    cursor: pointer;
}

@media screen and (max-width:600px){
    .common_modal_input{
        width: 88% !important;
    }
}