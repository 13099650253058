.items_list_main{
    margin-top: 100px !important;
}

.center_content_div{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.item_list .col-1 .item_image{
    height: 80px;
    width: 80px;
}

@media screen and (max-width: 900px){
    .items_list_main{
        margin-top: 160px !important;
    }
}