.address_page .row .address_page_inner{
    margin-top: 175px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0 22px;
}

.left_addresss_bar ul li {
    padding: 15px 0 !important;
    cursor: pointer;
    padding-left: 12px !important;
}

.left_addresss_bar{
    border-right: 0.2px solid rgb(231, 229, 229);
    padding: 23px 0 120px 0 !important;
}

.left_addresss_bar ul li:hover{
    background-color: #FAFAFA;
}

.left_addresss_bar ul .active{
    background-color: #FAFAFA;
}


.right_address_container .add_address_btn{
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    background-color: #0C831F;
}

.right_address_container .add_address_btn:hover{
    background-color: rgba(6, 109, 6, 0.83) !important;
}

.right_address_container .address_list .add_address_bar{
    padding: 12px 15px !important;
}

.address .full_address .full_address_func p:hover{
    text-decoration: underline ;
}
.address .full_address .full_address_func span:hover{
    text-decoration: underline ;
}

.address .full_address .full_address_func .delete_popup p{
    text-decoration: none !important;
}

.full_address .delete_popup{
    width: 280px;
    right: -50px;
    z-index: 1000;
}

@media screen and (max-width: 900px){
    .address_page .row .address_page_inner{
        margin-top: 225px !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 0 22px;
    }
}