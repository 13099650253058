.modal_main_style-content{
    padding: 20px !important;
    height: 194px !important;
    width: 40% !important;
    border-radius: 9px;
    margin-top: 250px !important;
}

.return_refund_page{
    overflow: auto;
}

@media screen and (max-width:900px){
    .modal_main_style-content{
        padding: 20px !important;
        height: 194px !important;
        width: 55% !important;
        border-radius: 9px;
        margin-top: 250px !important;
    }
}

@media screen and (max-width:600px){
    .modal_main_style-content{
        padding: 20px !important;
        height: 194px !important;
        width: 95% !important;
        border-radius: 9px;
        margin-top: 250px !important;
    }
}